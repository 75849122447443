import { faqItems } from "./utils";
import ExpandableBox from "./components/ExpandableBox";
import { useState } from "react";

const Faqs = (): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState(0);

  const clickHandler = (idx: number) => {
    setActiveIndex((current) => (current === idx ? -1 : idx));
  };

  return (
    <section className="rounded-tl-3xl rounded-tr-3xl">
      <div className="px-5 md:px-40 lg:px-[12rem] xl:px-[25rem] md:pt-8 md:pb-20">
        <div className="flex flex-col gap-6 md:gap-14 justify-center py-10 md:py-0">
          <div className="flex flex-col gap-5">
            <h2 className="text-2xl md:text-4xl font-bold text-center text-landing-blue">
              Frequently asked questions
            </h2>
            <p className="text-[11px] md:text-[20px] text-base text-grey-500 text-center">
              Everything you need to know about Klink
            </p>
          </div>

          <div className="flex flex-col justify-center">
            {faqItems.map((faq, idx) => (
              <div key={idx}>
                <ExpandableBox
                  question={faq.question}
                  answer={faq.answer}
                  active={activeIndex === idx}
                  clickHandler={() => clickHandler(idx)}
                  showOnlyBottomBorder={idx === faqItems.length - 1}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="text-center mb-6 md:m-4">
          <a
            href="/faqs"
            className="px-10 py-3 text-center text-base text-white font-bold transition duration-300 ease-in-out transform rounded-3xl shadow bg-magenta hover:scale-105 cursor-pointer"
          >
            See all FAQs
          </a>
        </div>
      </div>
    </section>
  );
};

export default Faqs;
