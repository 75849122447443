import { Props } from "../types";
import Image from "next/image";

const StepCard = ({index, title, subtitle, image}: Props): JSX.Element => {
  return (
    <div className="bg-grayWhite flex flex-col justify-center items-start md:gap-3 md:w-1/3 px-6 py-8 rounded-3xl">
      <div className="flex items-center justify-center bg-magenta text-base w-10 h-10 text-white rounded-full font-bold">
          {index}
      </div>

      <div className="flex justify-center w-full">
          <Image
              src={image}
              alt={title}
              objectFit="contain"
              priority
              width={160}
              height={160}
          />
      </div>

      <div className="flex-1 text-center md:text-left mt-10">
          <h3 className="text-lg font-bold text-landing-blue mb-2">{title}</h3>
          <p className="text-base">{subtitle}</p>
      </div>
    </div>
  );
};

export default StepCard;
