import Image from "next/image";
import ArticleList from "./components/ArticleList";
import CarouselComponent from "@/components/koach/Shared/CarouselComponent";
import HighlightedArticle from "./components/HighlightedArticle";
import routes from "@/constants/routes";
import Link from "next/link";
import { getGuidesQuery } from "@/services/cms/queries";

const Knowledge = (): JSX.Element => {
  const {
    isLoading: isGuidesLoading,
    isError: guidesError,
    data: guides,
  } = getGuidesQuery({
    populate: "category, cover",
    sort: "addedOn:desc",
    pagination: { start: 0, limit: 4 },
  });

  return (
    <section className="px-5 lg:px-11 xl:px-32 py-10 md:py-24 mt-10 md:my-24 bg-grayWhite">
      <div className="flex flex-col md:flex-row justify-between md:items-center gap-7">
        <div className="flex flex-col gap-5">
          <h2 className="text-2xl md:text-4xl font-bold">
            Build some financial muscle so you can buy your future home
          </h2>
          <p className="text-[11px] md:text-[18px] text-base mb-2 md:mb-0 text-grey-500">
            Learn about money saving tips, how to qualify for a mortgage and
            other essential information to become a future home owner.
          </p>
          <p className="text-[11px] md:text-[20px] text-base text-grey-500 text-center"></p>
        </div>

        <div className="flex cursor-pointer gap-1 items-center">
          <Link href={routes.guides}>
            <a className="font-bold text-magenta">See all guides</a>
          </Link>
          <Image
            src="/koach/icons/arrow-right.svg"
            width={16}
            height={16}
            alt="Right arrow"
          />
        </div>
      </div>

      {guides && guides.data && guides.data.length > 0 ? (
        <>
          {/* Articles */}
          <div className="hidden md:block">
            {isGuidesLoading ? (
              <p>Loading...</p>
            ) : guidesError ? (
              <p>Error occured while fetching the data!</p>
            ) : (
              <ArticleList guides={guides.data} />
            )}
          </div>

          <div className="md:hidden my-5 md:my-10">
            {isGuidesLoading ? (
              <p>Loading...</p>
            ) : guidesError ? (
              <p>Error occured while fetching the data!</p>
            ) : (
              <CarouselComponent data={guides.data}>
                {(guide: any, index: number) => (
                  <HighlightedArticle guide={guide} baseUrl={routes.guides} />
                )}
              </CarouselComponent>
            )}
          </div>
        </>
      ) : (
        <p className="text-center p-10">No data found!</p>
      )}
    </section>
  );
};

export default Knowledge;
