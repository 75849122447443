import React, { useState } from "react";
import QRCodeModal from "../Shared/QRCodeModal";
import { mobileConversionStore } from "@/components/shared/gtm";
import isMobile from "ismobilejs";
import getConfig from "next/config";

const SignupButtonComponent = () => {
  const [showModal, setShowModal] = useState(false);
  const { publicRuntimeConfig } = getConfig();

  const handleDownloadClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    const mobileDevice = isMobile(window.navigator);
    const isIpad = /iPad/.test(window.navigator.userAgent);

    if (mobileDevice.any) {
      if (mobileDevice.apple.device || isIpad || mobileDevice.apple.tablet) {
        mobileConversionStore({
          label: "apple-app-store",
          url: publicRuntimeConfig.NEXT_PUBLIC_APP_STORE_URL,
        });
        window.location.href = publicRuntimeConfig.NEXT_PUBLIC_APP_STORE_URL;
      } else if (mobileDevice.android.device) {
        mobileConversionStore({
          label: "google-play-store",
          url: publicRuntimeConfig.NEXT_PUBLIC_PLAY_STORE_URL,
        });
        window.location.href = publicRuntimeConfig.NEXT_PUBLIC_PLAY_STORE_URL;
      } else {
        setShowModal(true);
      }
    } else {
      setShowModal(true);
    }
  };

  return (
    <>
      <QRCodeModal showModal={showModal} onClick={() => setShowModal(false)} />
      <div className="flex w-full justify-center md:justify-start py-4 sm:py-0">
        <a
          href="https://www.klink-app.com/download"
          onClick={handleDownloadClick}
          className="px-10 py-3 text-center text-base text-white font-bold transition duration-300 ease-in-out transform rounded-3xl shadow bg-magenta hover:scale-105 cursor-pointer"
        >
          Download for free
        </a>
      </div>
    </>
  );
};

export default SignupButtonComponent;
