const feature1 = "/koach/set-your-home-ownership-goals.svg";
const feature2 = "/koach/understanding-your-spending.svg";
const feature3 = "/koach/get-there-faster.svg";

export const features = [
  {
    title: "Set your home ownership goals",
    subtitle:
      "Decide your home ownership goals to see how long it will take to get there and what might be holding you back.",
    image: feature1,
  },
  {
    title: "Understanding your spending",
    subtitle:
      "Klink shows you how you are spending your money and what may damage your chances of buying a home later on.",
    image: feature2,
  },
  {
    title: "Get there faster",
    subtitle:
      "Unlock insight and personalised missions to save money and supercharge your deposit.",
    image: feature3,
  },
];
