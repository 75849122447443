import Link from "next/link";
import Image from "next/image";
import routes from "@/constants/routes";

import StepCard from "./components/StepCard";
import QRCodeModal from "../Shared/QRCodeModal";
import { useState } from "react";
import { handleSignUpClick } from "@/components/koach/Shared/Helper";

const Steps = (): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  const steps = [
    {
      title: "Download and set your goal",
      subtitle:
        "Sign up to Klink through the mobile app by telling us a few things about yourself.",
      image: "/koach/step1.svg",
    },
    {
      title: "Set your home buying goal",
      subtitle:
        "Decide on your target home value and target deposit and see how long it will take you to get there.",
      image: "/koach/step2.svg",
    },
    {
      title: "Buy a home faster",
      subtitle:
        "Using spending insights and personalised missions, increase your savings and earn rewards.",
      image: "/koach/step3.svg",
    },
  ];

  return (
    <section className="px-5 md:px-10 lg:px-20">
      <QRCodeModal showModal={showModal} onClick={() => setShowModal(false)} />
      <div className="rounded-3xl">
        <div className="flex flex-col gap-4 md:gap-6 justify-center py-14">
          <div className="flex flex-col justify-center gap-5">
            <h2 className="text-2xl md:text-4xl font-bold text-landing-blue flex flex-col justify-center items-center text-center gap-2">
              How it works
              <p className="w-full text-[11px] md:text-[20px] text-base font-thin mt-3 md:mt-5 max-w-sm  md:max-w-md lg:max-w-xl">
                It’s free and easy to start using Klink. Just download the app
                on your smartphone and begin your journey!
              </p>
            </h2>
          </div>

          <div className="flex flex-col md:flex-row gap-6 md:gap-10 justify-between">
            {steps.map((step, index) => (
              <StepCard
                key={index}
                index={index + 1}
                title={step.title}
                subtitle={step.subtitle}
                image={step.image}
              />
            ))}
          </div>

          <div className="flex cursor-pointer gap-1 items-center"></div>
          <div className="flex justify-center">
            <Link href={routes.howItWorks} passHref>
              <a className="px-5 lg:px-16 py-3 w-full md:w-auto text-center text-base font-semibold text-white transition duration-300 ease-in-out transform rounded-3xl shadow bg-magenta hover:scale-105 cursor-pointer">
                How It Works
                <span className="pl-5">
                  <Image
                    src="/icons/arrow-right-white.svg"
                    width={16}
                    height={16}
                    alt="Right arrow"
                  />
                </span>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Steps;
