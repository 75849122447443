import Image from "next/image";
import { CarouselProps } from "../types";
import { formatDate } from "@/components/koach/Shared/Helper";
import Link from "next/link";
import { getGuideTextColor, getGuideBgColor } from "@/components/koach/Shared/Helper";

const HighlightedArticle = ({ guide, baseUrl}: CarouselProps): JSX.Element => {
  const addedOn = formatDate(guide?.attributes?.addedOn);
  const bgColor = getGuideBgColor(guide?.attributes?.category?.data?.attributes?.slug);
  const textColor = getGuideTextColor(guide?.attributes?.category?.data?.attributes?.slug);

  return (
    <Link href={baseUrl + "/" + guide?.attributes?.slug}>
      <div className="relative w-full h-full min-h-[32rem] md:min-h-[37rem] cursor-pointer">
        <Image 
          src={guide?.attributes?.cover?.data?.attributes?.formats?.large?.url}
          alt={guide?.attributes?.title}
          priority
          layout="fill"
          objectFit="cover"
          className="rounded-2xl" 
        />
      
        <div className="absolute bottom-3 left-3 right-3 md:left-5 md:right-5 md:bottom-5 p-4 md:p-6 rounded-2xl bg-white flex flex-col gap-5">
          <div className="w-full flex items-center gap-4 md:gap-7 text-left text-[11px] sm:text-[12px] xl:text-xs">
            <div className={`rounded-full ${bgColor} px-3 py-1`}>
              <p className={`font-bold ${textColor}`}>
                {guide?.attributes?.category?.data?.attributes?.name}
              </p>
            </div>
            <div className="border-r border-grey-200 py-3"></div>
            <p className="text-grey-400">{addedOn} · {guide?.attributes?.readingTime} min read</p>
          </div>

          <h3 className="font-semibold text-lg md:text-[23px] text-left">
            <span className="block md:inline">{guide?.attributes?.title}</span>
          </h3>

          <p className="text-base text-grey-400 text-left">
            {guide?.attributes?.subTitle}
          </p>

          <p className="text-base font-semibold text-magenta cursor-pointer text-left mt-auto hover:underline">
            Read More
          </p>
        </div>
      </div>
    </Link>
  );
};

export default HighlightedArticle;
