import { FaqItems } from "./types";

export const faqItems: FaqItems = [
  {
    question: "What is the Klink App?",
    answer:
      "Klink is here to help you realise your homeownership dreams. It helps you set a goal and gives you a timeline as well as helpful insights and tips on how to hit your goal. Klink also helps you understand what is needed throughout the homebuyer journey, with simple language and bitesize learning with personalised insight.",
  },
  {
    question: "When will the Klink App be available to download?",
    answer: "Very soon! We're making the finishing touches now. If you'd like to be first in line, sign up and we'll notify you the moment it's here."
  },
  {
    question: "Is the Klink app free?",
    answer:
      "Yes, the Klink App is free to use. You will only be charged if you subscribe for further insights, which are opt-in only. There’s plenty to do in the Klink App free of charge!",
  },
  {
    question: "How secure is the Klink app?",
    answer:
      "Security is incredibly important to us. We use Open Banking systems to connect to your accounts. Open Banking was introduced by the UK government to provide a secure way to connect your accounts with third parties without having to share your log-in details with us. Klink uses end-to-end 256-bit TLS encryption which is used by all major banks.",
  },
  {
    question: "Will my credit score be impacted?",
    answer:
      "Klink does not perform a credit check and does not share any information with credit referencing agencies. <br/><br/>Using Klink may in fact <i>improve</i> your credit score by adopting financially responsible behaviours.",
  },
  {
    question: "Can all bank accounts be connected?",
    answer:
      "All major banks are available through Open Banking, but there are some smaller providers that haven’t activated it yet.",
  },
];
