import Image from "next/image";
import Card from "./components/Card";

const Goals = (): JSX.Element => {
  return (
    <div className="relative px-5 md:px-16 lg:px-52 overflow-hidden">
      <div className="flex flex-col gap-6 md:gap-20 my-10 md:my-20 justify-center">
        <div className="flex flex-col gap-4 justify-center items-center">
          <h2 className="text-2xl md:text-4xl font-bold text-center">
            Get started today
            <p className="w-full text-[11px] md:text-[20px] text-base text-grey-500 text-center mt-3 md:mt-5 max-w-sm  md:max-w-md lg:max-w-xl font-thin">
              It’s taking longer and longer for first time buyers to get on the
              property ladder, if at all. Klink is here to help people get there
              sooner.
            </p>
          </h2>
        </div>

        <div className="flex flex-col md:flex-row gap-10 justify-between">
          <Card
            title="Work towards your goals that makes sense"
            subtitle="All good plans start with a goal in mind. Make yours today."
            image="/koach/goal-icon.svg"
          />

          <Card
            title="Get detailed spending insights and missions"
            subtitle="Klink can review your outgoings and help you save more."
            image="/koach/spending-icon.svg"
          />

          <Card
            title="Get rewarded daily by using Klink"
            subtitle="Earn Klink coins and get rewarded with cash towards your deposit."
            image="/koach/reward-icon.svg"
          />
        </div>
      </div>
    </div>
  );
};

export default Goals;
