import { useWindowSize } from "@/shared/hooks/useWindowSize";
import Screen from "@/constants/screens";
import CarouselDesktop from "./components/CarouselDesktop";
import CarouselMobile from "./components/CarouselMobile";

const Features = (): JSX.Element => {
  const { width } = useWindowSize();
  return (
    <section className="py-5 md:py-16 lg:py-16 px-5 md:px-10 lg:px-20">
      <div className="bg-grayWhite px-5 md:px-16 lg:px-24 py-10 md:py-20 rounded-3xl md:w-11/12">
        <h2 className="text-3xl md:text-4xl font-bold lg:text-5xl text-center md:text-left mb-5">
          Build your home ownership plan with Klink
        </h2>
        <div className="md:w-8/12 lg:w-7/12 text-center md:text-left mb-10 md:mb-10">
          <p className="text-[11px] md:text-[18px] text-base mb-2 md:mb-0 text-grey-500">
            All great plans start with a realistic goal. Set your home buying
            budget using your income, your savings potential and where you would
            like to live in the future.
          </p>
        </div>

        {width >= Screen.Tablet_768 && <CarouselDesktop />}
        {width < Screen.Tablet_768 && <CarouselMobile />}
      </div>
    </section>
  );
};

export default Features;
