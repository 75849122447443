import React, { ReactElement } from "react";
import Head from "next/head";
import Hero from "@/components/koach/Hero";
import Features from "@/components/koach/Features";
import Missions from "@/components/koach/Missions";
import Goals from "@/components/koach/Goals";
import Steps from "@/components/koach/Steps";
import Knowledge from "@/components/koach/Knowledge";
import Faqs from "@/components/koach/Faqs";
import SignupForm from "@/components/koach/Signup";

import getConfig from "next/config";
import { MainLayout } from "@/containers/layout/KoachLayout";

const Klink = (): JSX.Element => {
  return (
    <>
      <Head>
        <title>Klink | The All-In-One App for Future Home Owners</title>
        <link rel="canonical" href="https://www.klink-app.com" />
        <meta
          name="description"
          content="Klink App helps prospective home buyers get onto the ladder. The unique saving, budgeting and insights features will pave the way to your future home."
        />

        <meta
          name="keywords"
          content="Klink, financial goals, smart spending, save money, financial milestones, savings missions"
        />
        <meta name="language" content="English" />
        <meta name="author" content="Klink App" />

        <meta
          name="twitter:card"
          content="Klink App helps prospective home buyers get onto the ladder. The unique saving, budgeting and insights features will pave the way to your future home."
          key="twcard"
        />
        <meta name="twitter:url" content="https://www.klink-app.com/" />
        <meta
          name="twitter:title"
          content="Klink | The All-In-One App for Future Home Owners"
        />
        <meta name="twitter:image" content="/meta-opengraph-landing.png" />

        <meta name="twitter:creator" content="klink_app" key="twhandle" />

        <meta
          property="og:url"
          content="https://www.klink-app.com"
          key="ogurl"
        />
        <meta
          property="og:image"
          content="/meta-opengraph-landing.png"
          key="ogimage"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:site_name" content="Klink" key="ogsitename" />
        <meta
          property="og:title"
          content="Klink | The All-In-One App for Future Home Owners"
          key="ogtitle"
        />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_GB" />
        <meta
          property="og:description"
          content="Klink App helps prospective home buyers get onto the ladder. The unique saving, budgeting and insights features will pave the way to your future home."
          key="ogdesc"
        />
      </Head>
      <div className="bg-white">
        <Hero />
        <Goals />
        <Features />
        <Steps />
        <Missions />
        <Knowledge />
        <Faqs />
        <SignupForm />
      </div>
    </>
  );
};

Klink.getLayout = function getLayout(page: ReactElement) {
  return (
    <>
      <MainLayout theme="dark">{page}</MainLayout>
    </>
  );
};

export default Klink;
