import Image from "next/image";
import { Props } from "../types";

const Card = ({ title, subtitle, image }: Props): JSX.Element => {
  return (
    <div
      className="flex flex-col gap-5 items-center md:w-48 xl:w-60">
      <div className="relative flex justify-center items-center">
        <Image 
          src={image} 
          alt={title} 
          objectFit="contain" 
          width={160}
          height={160}
        />
      </div>

      <div className="flex flex-col gap-3 text-center items-center">
        <h3 className="font-sans font-semibold text-xl">
          {title}
        </h3>

        <p className="font-sans text-base text-grey-500">
          {subtitle}
        </p>
      </div>
    </div>
  );
};

export default Card;
