import { useInfiniteQuery, useQuery } from "react-query";
import { getArticles, getGuides, getCategories, getGuideCategories, getTags } from "./api";
import { StrapiRequestParams } from "strapi-sdk-js";

export const getArticlesQuery = (params: StrapiRequestParams) =>
  useQuery(["getArticles", params], () => getArticles(params), {
    keepPreviousData: true,
    staleTime: Infinity,
  });

export const getArticlesPaginatedQuery = (params: StrapiRequestParams) =>
  useInfiniteQuery(
    ["getArticles", params],
    ({ pageParam = 1 }) => {
      return getArticles({ ...params, pagination: { page: pageParam, pageSize: 6 } });
    },
    {
      getNextPageParam: (lastPage) => {
        // lastPage signature depends on your api respond, below is a pseudocode
        if ((lastPage.meta.pagination?.page || 0) < (lastPage.meta.pagination?.pageCount || 0)) {
          return (lastPage.meta.pagination?.page || 0) + 1;
        }
        return undefined;
      },
    },
  );

export const getCategoriesQuery = () => useQuery(["getCategories"], () => getCategories());

export const getGuideCategoriesQuery = () => useQuery(["getGuideCategories"], () => getGuideCategories());

export const getTagsQuery = () => useQuery(["getTags"], () => getTags());

export const getGuidesQuery = (params: StrapiRequestParams) => 
  useQuery(["getGuides", params], () => {
    // Append the showOnWeb filter to the existing filters
    const updatedFilters = { ...params.filters, showOnWeb: true };
    
    // Merge the updated filters with the existing params
    const updatedParams = {
      ...params,
      filters: updatedFilters
    };

    return getGuides(updatedParams);
  }, {
    keepPreviousData: true,
    staleTime: Infinity,
  });

export const getGuidesInfiniteQuery = (params: StrapiRequestParams) =>
  useInfiniteQuery(
    ["getGuidesMobile", params],
    ({ pageParam = 1 }) => {
      return getGuides({ ...params, pagination: { page: pageParam, pageSize: 6 } });
    },
    {
      getNextPageParam: (lastPage) => {
        if ((lastPage.meta.pagination?.page || 0) < (lastPage.meta.pagination?.pageCount || 0)) {
          return (lastPage.meta.pagination?.page || 0) + 1;
        }
        return undefined;
      },
    },
  );
  