import Image from "next/image";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useState, useEffect } from "react";
import { features } from "../utils";

const CarouselMobile = (): JSX.Element => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [paused, setPaused] = useState(false);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % features.length);
    }, 5000)

      if (paused) {
        clearInterval(interval)
      }

      return () => {
          clearInterval(interval)
      }
  }, [paused]);

  const onMouseEnter = (selectedIndex: number) => {
    setCurrentIndex(selectedIndex);
    setPaused(true);
  };

  const onPreviousButtonClick = () => {
    setCurrentIndex((currentIndex) => {
      const updatedIndex = (currentIndex - 1) % features.length;
      return updatedIndex === -1 ? features.length - 1 : updatedIndex;
    });
  }

  const onNextButtonClick = () => {
    setCurrentIndex((currentIndex) => (currentIndex + 1) % features.length);
  }

  return (
    <div className="md:hidden flex flex-col md:flex-row justify-between items-center gap-5">
        {/* LHS */}
        <div className="w-full relative h-64 overflow-hidden">
          {features.map((feature, index) => (
            <div 
              key={index}
              className={`transition-opacity ${
                index === currentIndex ? "opacity-100" : "opacity-0"
              } absolute inset-0 duration-1000 ${
                index === currentIndex ? "delay-500" : ""
              }`}
            >
              <Image
                src={feature.image}
                alt="Feature image"
                objectFit="contain"
                priority
                layout="fill"
              />
            </div>
          ))}
        </div>
          
        {/* RHS */}
        <div className="flex flex-col gap-6 w-full">
          <Carousel
            showIndicators={false}
            showStatus={false}
            showArrows={false}
            showThumbs={false}
            swipeable={false}
            axis="horizontal"
            selectedItem={currentIndex}
            renderItem={(item) => (
              <div>
                {item}
              </div>
            )}
          >
            {features.map((feature, index) => (
                <div 
                  className="flex flex-col gap-5" 
                  key={index} 
                  onMouseEnter={() => onMouseEnter(index)}
                  onMouseLeave={() => setPaused(false)}
                >
                  <h3 className="text-2xl font-bold text-landing-blue">
                      {feature.title}
                  </h3>
                  
                  <h3 className="text-sm text-base text-grey-500">
                      {feature.subtitle} 
                  </h3>
                </div>
              )
            )}
          </Carousel>
          
          {/* Navigation buttons*/}
          <div className="flex px-20">
            <div className="flex w-full justify-between">
              <Image
                  src="/koach/icons/carousel-arrow-left.svg"
                  alt="Left arrow image"
                  objectFit="contain"
                  priority
                  width={40}
                  height={40}
                  className="cursor-pointer"
                  onClick={onPreviousButtonClick}
              />

              <div className="flex items-center gap-2">
                {features.map((feature, index) => (
                  index === currentIndex ? (
                    <div key={index} className="flex items-center justify-center bg-magenta text-sm w-2 h-2 text-white rounded-full"></div>
                  ) : (
                    <div key={index} className="flex items-center justify-center bg-light-magenta text-sm w-2 h-2 text-white rounded-full "></div>
                  )
                ))}
              </div>
              
              <Image
                  src="/koach/icons/carousel-arrow-right.svg"
                  alt="Right arrow image"
                  objectFit="contain"
                  priority
                  width={40}
                  height={40}
                  className="cursor-pointer"
                  onClick={onNextButtonClick}
              />
            </div>
          </div>
        </div>
    </div>
  );
};

export default CarouselMobile;
