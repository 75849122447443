import { Props } from "../types";

const FeatureCard = ({ index, title, subtitle, active = true, onMouseEnter, onMouseLeave }: Props): JSX.Element => {
  return (
    <div 
      className={`flex gap-9 cursor-pointer`}
      onMouseEnter={() => onMouseEnter(index)}
      onMouseLeave={onMouseLeave}
    >  
      <div 
        className={`border-x-4 
          ${active ? "border-navy rounded-2xl" : "border-guides-paleUltraMarine rounded-b-full"} 
          ${index == 0 ? "rounded-y-full" : ""}
        `}
      >
      </div>

      <div className={`flex flex-col gap-4 my-7`}>
        <h2 className="text-2xl font-bold text-landing-blue">
          {title}
        </h2>
        
        <p className="text-base text-grey-500">
          {subtitle} 
        </p>
      </div>
    </div>
  );
};

export default FeatureCard;
