import Image from "next/image";
import CheckItem from "./components/CheckItem";
import QRCodeModal from "../Shared/QRCodeModal";
import { useState } from "react";
import { handleSignUpClick } from "@/components/koach/Shared/Helper";
import { useWindowSize } from "@/shared/hooks/useWindowSize";
import Screen from "@/constants/screens";

const Missions = () => {
  const [showModal, setShowModal] = useState(false);
  const { width } = useWindowSize();
  const gradientClasses =
    width >= Screen.Tablet_768
      ? ""
      : "bg-gradient-to-tr from-navy from-40% to-magenta to-100%";

  return (
    <section className="px-5 md:px-10 lg:px-20">
      <QRCodeModal showModal={showModal} onClick={() => setShowModal(false)} />
      <div
        className={`${gradientClasses} bg-navy px-5 md:px-10 lg:px-20 py-10 rounded-3xl overflow-hidden items-center`}
      >
        <div className="flex flex-col gap-8 pt-5 md:pt-10 md:pb-10">
          <div className="relative">
            <div
              className="hidden md:block absolute w-[50rem] h-[40rem] -top-[24rem] -left-[10rem]"
              style={{
                background:
                  "radial-gradient(50% 50% at 50% 50%, #FE1FAC 0%, rgba(254, 31, 172, 0.00) 90%)",
              }}
            ></div>
            <div className="relative ">
              <h2 className="text-3xl lg:text-4xl text-center md:text-left font-bold text-white mb-5">
                Buy your home sooner
              </h2>

              <p className="text-[11px] md:text-[20px] text-base mb-2 md:mb-0 text-center md:text-left text-white">
                Unlock insight and personalised missions to save money and
                supercharge your deposit.
              </p>
            </div>
          </div>

          <div className="w-full flex flex-col md:flex-row gap-5 md:gap-10 items-center">
            <div className="flex flex-col gap-5 w-full md:w-2/6">
              <CheckItem text="Reduce your clothing shopping by 15% to save £30 this week" />
              <CheckItem text="Reduce your entertainment spend by 20% to save £60 this month" />
            </div>

            <div className="flex flex-col flex-col-reverse md:flex-row gap-10 items-center md:w-4/6">
              <div className="relative min-h-[330px] w-full md:w-2/4">
                <Image
                  src="/koach/home-illustration.svg"
                  alt="Home Illustration"
                  layout="fill"
                  objectFit="contain"
                />
              </div>

              <div className="flex flex-col gap-5 md:w-2/4">
                <CheckItem text="Reduce Eating out and Takeaways by 30% to save £50 per month" />
                <CheckItem text="Your missions have saved you £170 this month. Keep it up and you could be ready to buy 1 year faster" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Missions;
