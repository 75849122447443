import Image from "next/image";
import { useState, useEffect } from "react";
import SignupButtonComponent from "../Shared/SignupButtonComponent";

const Hero = () => {
  const [index, setIndex] = useState(0);
  const words = [
    "get a mortgage",
    "save cash",
    "earn rewards",
    "build a plan",
    "buy a home",
    "follow a budget",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  const getAnimationClass = (currentIndex: number) => {
    switch (currentIndex % 4) {
      case 0:
        return "flip-up";
      case 1:
        return "flip-up2";
      case 2:
        return "flip-up";
      case 3:
        return "flip-up2";
      default:
        return "flip-up2";
    }
  };

  const bulletPoints = [
    "Klink helps you set a home buying goal",
    "Boost your deposit savings and get rewarded",
    "Improve your home ownership chances",
  ];

  return (
    <>
      <div className="md:pt-10 grid grid-cols-1 md:grid-cols-2 gap-1 md:gap-0 bg-navy w-full overflow-hidden text-white md:px-6 px-5 lg:px-11 xl:px-32">
        <div className="flex gap-2 md:gap-8 flex-col items-center md:items-start pt-10 z-20 md:mb-20">
          <h1 className="text-4xl lg:text-5xl 2xl:text-6xl font-bold text-center md:text-left text-landing-white leading-[1.1]">
            <span className="hidden md:inline">The </span>{" "}
            <span className="hidden md:inline-block bg-gradient-to-r from-magenta to-landing-orange bg-clip-text text-transparent">
              home ownership
            </span>
            <span className="md:hidden inline-block bg-gradient-to-r from-magenta to-landing-orange bg-clip-text text-transparent">
              Home ownership
            </span>{" "}
            <span className="inline-block">super app to</span>
            <br />
            <span
              className={`bg-gradient-to-r from-magenta to-landing-orange bg-clip-text text-4xl lg:text-5xl 2xl:text-6xl text-transparent pb-8 inline-block animate-${getAnimationClass(
                index
              )}`}
            >
              {words[index]}
            </span>
          </h1>
          <div className="hidden md:block text-lg text-light-white text-center md:text-left">
            {bulletPoints.map((point, index) => (
              <div className="flex gap-3 my-2 items-center" key={index}>
                <span className="rounded-full bg-amber w-[16px] h-[16px]"></span>
                {point}
              </div>
            ))}
          </div>

          <p className="md:hidden block px-1 text-xl text-grey-200 text-center">
            Klink helps you set a home buying goal, boost your savings, and earn
            rewards for managing money like a boss.
          </p>
          
          <SignupButtonComponent/>

        </div>
        <div className="bg-gray-200 flex items-end justify-center relative">
          <div
            className="absolute w-[72rem] h-[45rem] md:w-[100rem] md:h-[45rem] -bottom-[22rem] -right-[20.5rem] sm:-right-[15rem] sm:-bottom-[16rem] md:-bottom-[22rem] md:-right-[28rem] lg:-right-[22rem] lg:-bottom-[18rem]"
            style={{
              background:
                "radial-gradient(50% 50% at 50% 50%, #FE1FAC 0%, rgba(254, 31, 172, 0.00) 90%)",
            }}
          ></div>
          {/* DESKTOP */}
          <div className="hidden sm:block relative w-full flex items-end justify-center md:-left-[4rem] sm:-bottom-[0.45rem]">
            <Image
              src="/koach/Hero-Desktop.png"
              alt="Klink Hero"
              priority
              width={800}
              height={580}
              className="w-full h-auto object-contain"
            />
          </div>

          {/* MOBILE */}
          <div className="sm:hidden relative w-full flex items-end justify-center">
            <Image
              src="/koach/Hero-Mobile.png"
              alt="Klink Hero"
              priority
              width={714}
              height={490}
              className="w-full h-auto object-contain"
            />
          </div>
        </div>{" "}
      </div>
    </>
  );
};

export default Hero;
