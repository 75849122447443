import Image from "next/image";
import { useRef } from "react";
import { ExpandableBoxProps } from "../../types";

const ExpandableBox = ({
  question,
  answer,
  active,
  clickHandler,
  showOnlyBottomBorder,
}: ExpandableBoxProps) => {
  const contentSpace = useRef<HTMLDivElement>(null);
  const image = active
    ? "/koach/icons/collapse-icon.svg"
    : "/koach/icons/expand-icon.svg";

  return (
    <div
      className={`py-6 text-landing-blue border-grey-300 ${
        !showOnlyBottomBorder && "border-b"
      } `}
    >
      <div
        className="flex items-center justify-between md:cursor-pointer"
        onClick={clickHandler}
      >
        <div className="w-10/12 font-bold md:font-medium">{question}</div>
        <div className={`flex justify-end w-2/12`}>
          <Image
            className={`transform duration-700 ease`}
            src={image}
            width={26}
            height={26}
            alt="chevron"
          />
        </div>
      </div>

      <div
        ref={contentSpace}
        style={{
          maxHeight: active
            ? `${contentSpace?.current?.scrollHeight}px`
            : "0px",
        }}
        className={`overflow-hidden text-base transition-all duration-700 ease-in-out ${
          active && "pt-5 md:pt-5"
        }`}
      >
        <div className="flex text-grey-500">
        <div dangerouslySetInnerHTML={{ __html: answer }}
            className="md:w-10/12"
            style={{ color: "rgba(39, 48, 67, 0.7)" }}
          >
          </div>
          <div className="w-2/12"></div>
        </div>

        <div className="pb-8" />
      </div>
    </div>
  );
};

export default ExpandableBox;
