import Image from "next/image";
import { Props } from "../types";

const CheckItem = ({ text }: Props): JSX.Element => {
  return (
    <div className="relative p-7 border border-violetBlue rounded-3xl bg-gradient-to-tr from-navy from-20% to-violetBlue to-100% ">  
      <div className="absolute -top-2 -right-2">
        <Image
          src="/koach/check-icon.svg"
          alt="Check icon"
          objectFit="contain"
          priority
          width={30}
          height={30}
        />
      </div> 
     
      <p className="text-lg font-normal text-white">
        {text}
      </p>
    </div>
  );
};

export default CheckItem;
