import Image from "next/image";
import { CardProps } from "../types";
import Link from "next/link";
import {
  getGuideTextColor,
  getGuideBgColor,
  formatDate,
} from "@/components/koach/Shared/Helper";

const Card = ({ baseUrl, guide }: CardProps): JSX.Element => {
  const addedOn = formatDate(guide?.attributes?.addedOn);
  const bgColor = getGuideBgColor(
    guide?.attributes?.category?.data?.attributes?.slug
  );
  const textColor = getGuideTextColor(
    guide?.attributes?.category?.data?.attributes?.slug
  );

  return (
    <Link href={baseUrl + "/" + guide?.attributes?.slug}>
      <a className="cursor-pointer bg-white flex flex-col md:flex-row md:gap-5 items-center lg:items-start w-full h-full rounded-2xl">
        <div className="relative w-[180px] h-[186px]">
          <Image
            src={
              guide?.attributes?.cover?.data?.attributes?.formats?.large?.url
            }
            alt={guide?.attributes?.title}
            priority
            objectFit="cover"
            layout="fill"
            className="rounded-2xl"
          />
        </div>

        <div className="flex-1 py-4 pr-5 flex flex-col items-center md:items-start gap-3 md:gap-5 h-full">
          <div className="w-full flex flex-col md:flex-row items-center gap-5 text-[14px]">
            <div className={`rounded-full ${bgColor} px-3 py-1`}>
              <p className={`font-bold ${textColor}`}>
                {guide?.attributes?.category?.data?.attributes?.name}
              </p>
            </div>
            <div className="border-r border-grey-200 py-3"></div>
            <p className="text-right text-grey-400">
              {addedOn} · {guide?.attributes?.readingTime} min read
            </p>
          </div>

          <h3 className="font-semibold text-xl lg:text-[20px] xl:text-[22px]">
            {guide?.attributes?.title}
          </h3>

          <p className="text-base font-bold text-blue-600 cursor-pointer mt-auto text-magenta hover:underline">
            Read More
          </p>
        </div>
      </a>
    </Link>
  );
};

export default Card;
