import Image from "next/image";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useState, useEffect } from "react";
import { CarouselComponentProps } from "./types";

const CarouselComponent = ({ children, data }: CarouselComponentProps): JSX.Element => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [paused, setPaused] = useState(false);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
    }, 5000)

    if (paused) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    }
  }, [paused]);

  const onMouseEnter = (selectedIndex: number) => {
    setCurrentIndex(selectedIndex);
    setPaused(true);
  };

  const onPreviousButtonClick = () => {
    setCurrentIndex((currentIndex) => {
      const updatedIndex = (currentIndex - 1) % data.length;
      return updatedIndex === -1 ? data.length - 1 : updatedIndex;
    });
  }

  const onNextButtonClick = () => {
    setCurrentIndex((currentIndex) => (currentIndex + 1) % data.length);
  }

  return (
    <div className="flex flex-col gap-6 w-full">
      <Carousel
        showIndicators={false}
        showStatus={false}
        showArrows={false}
        showThumbs={false}
        swipeable={false}
        axis="horizontal"
        selectedItem={currentIndex}
        renderItem={(item) => (
          <div>
            {item}
          </div>
        )}
      >
        {data.map((item, index) => (
          <div 
            key={index} 
            onMouseEnter={() => onMouseEnter(index)}
            onMouseLeave={() => setPaused(false)}
          >
            {typeof children === 'function' && children(item, index)}
          </div>
        ))}
      </Carousel>
      
      {/* Navigation buttons*/}
      <div className="flex px-20">
        <div className="flex w-full justify-between">
          <Image
              src="/koach/icons/carousel-arrow-left.svg"
              alt="Left arrow image"
              objectFit="contain"
              priority
              width={40}
              height={40}
              className="cursor-pointer"
              onClick={onPreviousButtonClick}
          />

          <div className="flex items-center gap-2">
            {data.map((item, index) => (
              index === currentIndex ? (
                <div key={index} className="flex items-center justify-center bg-magenta text-sm w-2 h-2 text-white rounded-full"></div>
              ) : (
                <div key={index} className="flex items-center justify-center bg-light-magenta text-sm w-2 h-2 text-white rounded-full "></div>
              )
            ))}
          </div>
          
          <Image
              src="/koach/icons/carousel-arrow-right.svg"
              alt="Right arrow image"
              objectFit="contain"
              priority
              width={40}
              height={40}
              className="cursor-pointer"
              onClick={onNextButtonClick}
          />
        </div>
      </div>
    </div>
  );
};

export default CarouselComponent;
