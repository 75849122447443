import HighlightedArticle from "./HighlightedArticle";
import Card from "./Card";
import routes from "@/constants/routes";
import { ArticleListProps } from "../types";

const ArticleList = ({ guides } : ArticleListProps): JSX.Element => {
  const highlightedGuide = guides?.[0];
  const remainingGuides = guides?.slice(1);
  
  return (
    <div className="flex flex-col lg:flex-row justify-between py-5 md:py-10 gap-10">
      <div className="w-full">
        <HighlightedArticle
          guide={highlightedGuide}
          baseUrl={routes.guides}
        />
      </div>

      <div className="w-full flex flex-col gap-5">
        {remainingGuides.map((guide, index) => {
          return (
            <Card 
              baseUrl={routes.guides}
              key={index}
              guide={guide}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ArticleList;
